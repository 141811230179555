<template>
  <div id="app-root">
    <GlobalChat />
  </div>
</template>

<script>
import GlobalChat from "./components/GlobalChat.vue";

export default {
  name: "App",
  components: {
    GlobalChat,
  },
};
</script>

<style scoped>
body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  margin: 0;
}
.chat-icon {
  /* Styles here */
}
/* Add the rest of your styles here */
</style>
