<template>
  <div id="app-root">
    <GlobalChat />
  </div>
</template>

<script>
import GlobalChat from "./components/GlobalChat.vue";

export default {
  name: "App",
  components: {
    GlobalChat,
  },
};
</script>