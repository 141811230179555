<template>
  <div id="live-chat-icon" class="fixed-bottom p-3" style="left: 0">
    <!-- Live Chat Button -->
    <button
      v-if="!showChatForm && !showChatBox"
      @click="openChatForm"
      class="btn btn-primary btn-chat"
    >
      Chat
    </button>
    <!-- Chat form modal -->
    <div v-if="showChatForm" class="chat-form">
      <div>
        <div class="chat-head">
          <h5>Chat with us now</h5>
          <button @click="closeChatForm" class="btn-back"></button>
        </div>
        <form class="chat-login" @submit.prevent="submitChatForm">
          <div class="form-group">
            <input
              v-model="form.email"
              type="email"
              class="form-control"
              placeholder="Enter email address *"
              :class="{ 'is-invalid': errors.email }"
            />
            <div v-if="errors.email" class="error">
              {{ errors.email }}
            </div>
          </div>
          <div class="form-group">
            <input
              v-model="form.firstname"
              type="text"
              class="form-control"
              placeholder="Enter first name *"
              :class="{ 'is-invalid': errors.firstname }"
            />
            <div v-if="errors.firstname" class="error">
              {{ errors.firstname }}
            </div>
          </div>
          <div class="form-group">
            <input
              v-model="form.lastname"
              type="text"
              class="form-control"
              placeholder="Enter last name *"
              :class="{ 'is-invalid': errors.lastname }"
            />
            <div v-if="errors.lastname" class="error">
              {{ errors.lastname }}
            </div>
          </div>
          <div class="form-group">
            <input
              v-model="form.mobile_no"
              type="text"
              class="form-control"
              placeholder="Enter telephone number *"
              :class="{ 'is-invalid': errors.mobile_no }"
            />
            <div v-if="errors.mobile_no" class="error">
              {{ errors.mobile_no }}
            </div>
          </div>
          <div class="form-group">
            <textarea
              v-model="form.note"
              class="form-control"
              placeholder="Reason for contacting us today ? *"
              :class="{ 'is-invalid': errors.note }"
            ></textarea>
            <div v-if="errors.note" class="error">
              {{ errors.note }}
            </div>
          </div>
          <div class="form-group form-btns">
            <button type="submit" class="btn btn-primary">Start Chat</button>
          </div>
        </form>
      </div>
    </div>
    <!-- Chat Box -->
    <div v-if="showChatBox" class="chat-form">
      <div class="chat-head">
        <h5>
          {{
            chats.filter((chat) => chat.sender !== "You").length > 0
              ? chats.filter((chat) => chat.sender !== "You").slice(-1)[0]
                  .sender
              : ""
          }}
        </h5>
        <button @click="toggleChatBox" class="chat-form-minimize-btn">
          &minus;
        </button>
        <button @click="finishChat" class="chat-form-close-btn">
          &#10005;
        </button>
      </div>

      <div class="chat-box">
        <div v-for="(chat, index) in chats" :key="index" class="chat-message">
          <div v-if="chat.sender === 'You'" class="user">
            <div class="label">{{ chat.sender }}</div>
            <div class="message">
              {{ chat.message }}
            </div>
          </div>

          <div v-else>
            <div class="label">{{ chat.sender }}</div>
            <div class="message"><strong></strong>{{ chat.message }}</div>
          </div>
        </div>
      </div>

      <div class="input-group">
        <input
          v-model="newMessage"
          type="text"
          class="form-control"
          placeholder="Type a message..."
          @keydown.enter="sendMessage"
        />
        <div class="input-action">
          <button @click="sendMessage" class="btn btn-primary">Send</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import InfiniteLoading from "vue-infinite-loading";
export default {
  components: {
    InfiniteLoading,
  },

  mounted() {
    window.Pusher = Pusher;
    this.chatConfig = {
      broadcaster: process.env.VUE_APP_BROADCAST_DRIVER,
      key: process.env.VUE_APP_REVERB_APP_KEY,
      wsHost: process.env.VUE_APP_REVERB_HOST,
      wsPort: process.env.VUE_APP_REVERB_PORT,
      wssPort: process.env.VUE_APP_REVERB_PORT,
      forceTLS: process.env.VUE_APP_REVERB_SCHEME === "https" ? true : false,
      enabledTransports: ["ws", "wss", "sockjs"],
    };

    this.startGlobalChat();
    this.startGChat();

    const existingSessionId = localStorage.getItem("echo_sessionId");
    if (existingSessionId) {
      this.showChatForm = false; // Hide form if session ID exists
    } else {
      this.showChatForm = true; // Show form if no session ID
    }
  },

  data() {
    return {
      chatConfig: null,
      showChatForm: false,
      showChatBox: false,
      newMessage: "",
      chats: [],
      visitor_id: "",
      privateChannel: "",
      // serverUrl: siteSettings.apiUrl,
      sound: new Audio("/path/to/notification-sound.mp3"),
      form: {
        email: "",
        firstname: "",
        lastname: "",
        mobile_no: "",
        note: "",
        sessionId: "",
      },
      errors: {
        email: null,
        firstname: null,
        lastname: null,
        mobile_no: null,
        note: null,
      },
      serverUrl: process.env.VUE_APP_API_URL,
    };
  },
  beforeDestroy() {
    if (window.Echo) {
      window.Echo.disconnect();
    }
  },

  methods: {
    validateForm() {
      this.errors = {
        email: null,
        firstname: null,
        lastname: null,
        mobile_no: null,
        note: null,
      };

      let valid = true;
      if (!this.form.email || !this.isValidEmail(this.form.email)) {
        this.errors.email = "Please enter a valid email address.";
        valid = false;
      }
      if (!this.form.firstname) {
        this.errors.firstname = "First name is required.";
        valid = false;
      }
      if (!this.form.lastname) {
        this.errors.lastname = "Last name is required.";
        valid = false;
      }
      const phoneRegex = /^[0-9]{10}$/;
      if (!this.form.mobile_no) {
        this.errors.mobile_no = "Phone number is required.";
        valid = false;
      } else if (this.form.mobile_no.length !== 10) {
        this.errors.mobile_no = "Please enter a 10-digit phone number.";
        valid = false;
      } else if (!phoneRegex.test(this.form.mobile_no)) {
        this.errors.mobile_no = "Please enter a valid phone number.";
        valid = false;
      }

      if (!this.form.note) {
        this.errors.note = "Please provide a reason for contacting us.";
        valid = false;
      }

      return valid;
    },

    submitChatForm() {
      if (this.validateForm()) {
        this.showChatForm = false;
        this.showChatBox = true;
        this.form.sessionId = this.sessionId;
        axios
          .post(this.serverUrl + "chat", this.form)
          .then((response) => {
            this.visitor_id = response.data.contact.id;
            this.showChatForm = false;
            this.showChatBox = true;
            localStorage.setItem("echo_visitor_id", this.visitor_id);

            //alert("Ticket created successfully. You can now chat!");

            this.chats.push({
              sender: "You",
              message: this.form.note,
              created_at: new Date(),
              // image: this.imageUrl,
            });

            this.resetForm();
          })
          .catch((error) => {
            if (error.response) {
              alert(
                Object.values(error.response.data.errors).flat().join("\n")
              );
            } else {
              alert("An unexpected error occurred. Please try again.");
            }
          });
      }
    },

    isValidEmail(email) {
      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      return regex.test(email);
    },

    startGChat() {
      console.log("In GChat.");
      if (!window.Echo) {
        window.Echo = new Echo(this.chatConfig);
      }
      const existingPrivateChannel = localStorage.getItem(
        "echo_privateChannel"
      );
      if (existingPrivateChannel) {
        //call history function

        this.checkItsClose();
        this.startPrivate(existingPrivateChannel);
      } else {
        if (window.Echo) {
          Object.keys(window.Echo.connector.channels).forEach((channelName) => {
            console.log(
              "Channel:",
              channelName,
              "Details:",
              window.Echo.connector.channels[channelName]
            );
            if (channelName.includes("global-chat")) {
              window.Echo.leave("global-chat");
              console.log("Echo disconnected" + channelName);
            }
          });
        }

        window.Echo.channel("global-chat")
          .subscribed(() => {
            console.log("Successfully subscribed to global-chat channel.");
          })
          .listen(".global.message.sent", (event) => {
            console.log("Received to global-chat channel...");
            console.log("Event received:", event);
            try {
              const messageData = event.message;

              const msg = messageData.message;
              console.log("Received message:", msg);
              if (messageData.messageBy == "staff") {
                if (
                  this.visitor_id != "" &&
                  this.visitor_id != messageData.user_id
                ) {
                  if (messageData.chat_type == "private") {
                    // window.Echo.disconnect();
                    if (this.visitor_id == messageData.visitor_id) {
                      this.startPrivate(messageData.private_channel);
                    }
                  } else {
                    if (this.sessionId == messageData.visitorSessionId) {
                      this.chats.push({
                        sender: messageData.name,
                        message: msg,
                        image: messageData.image,
                      });
                    }

                    this.scrollToBottom();
                  }
                }
              }
            } catch (error) {
              console.error("Error parsing message data:", error);
            }
          });
      }
    },

    startPrivate(private_channel) {
      this.privateChannel = private_channel;

      localStorage.setItem("echo_privateChannel", this.privateChannel);
      if (!window.Echo) {
        window.Echo = new Echo(this.chatConfig);
      }

      if (window.Echo) {
        Object.keys(window.Echo.connector.channels).forEach((channelName) => {
          console.log(
            "Channel:",
            channelName,
            "Details:",
            window.Echo.connector.channels[channelName]
          );
          if (channelName.includes("global-chat")) {
            window.Echo.leave("global-chat");
            console.log("Echo disconnected" + channelName);
          }

          if (channelName.includes("prime-")) {
            window.Echo.leave(channelName);
            console.log("Echo disconnected" + channelName);
          }
        });
      }

      console.log("Starting private chat...prime-" + this.privateChannel);
      window.Echo.channel("prime-" + this.privateChannel)
        .subscribed(() => {
          console.log("Successfully subscribed to private-chat. channel.");
        })
        .listen(".prime.message.sent", (event) => {
          console.log("Received to private-chat channel...");
          console.log("Event received:", event);
          try {
            const messageData = event.message;
            const msg = messageData.message;
            console.log(this.visitor_id);
            console.log("Received message:", msg);
            if (this.visitor_id != messageData.user_id) {
              this.chats.push({
                sender: messageData.name,
                message: msg,
                created_at: messageData.created_at,
                image: messageData.image,
              });
              this.scrollToBottom();
              if (messageData.status === "closed") {
                this.showChatBox = false;
                // alert("The chat session has been closed.");
                window.Echo.disconnect();
                console.log("Left private channel:", this.privateChannel);
                this.chats = [];
                this.resetForm();
                this.removeStorage();
              }
            }
          } catch (error) {
            console.error("Error parsing message data:", error);
          }
        });
    },

    startGlobalChat() {
      const existingSessionId = localStorage.getItem("echo_sessionId");
      if (!existingSessionId) {
        axios.post(this.serverUrl + "chat/start-global").then((response) => {
          this.sessionId = response.data.session_id;
          localStorage.setItem("echo_sessionId", this.sessionId);
        });
      } else {
        console.log(
          "Session ID already exists:",
          localStorage.getItem("echo_sessionId")
        );
        this.sessionId = existingSessionId;
      }

      const existingVisitorId = localStorage.getItem("echo_visitor_id");
      if (existingVisitorId) {
        this.showChatForm = false;
        this.showChatBox = true;
        this.visitor_id = existingVisitorId;
      }
    },

    sendMessage() {
      if (this.newMessage.trim() !== "") {
        this.chats.push({
          sender: "You",
          message: this.newMessage,
          created_at: new Date(),
          // image: this.imageUrl,
        });
        axios
          .post(this.serverUrl + "chat/send-message", {
            session_id: this.sessionId,
            message: this.newMessage,
          })
          .then(() => {
            this.newMessage = "";
            this.scrollToBottom();
          });
      }
      this.newMessage = "";
    },

    async checkItsClose() {
      try {
        const response = await axios.get(
          `${this.serverUrl}chat_status/${this.sessionId}`
        );
        if (response.data) {
          if (response.data.status == "accepted") {
            this.loadChatHistory();
          } else {
            this.removeStorage();
          }
        }
      } catch (error) {
        console.error("Error fetching chat_status:", error);
      }
    },

    removeStorage() {
      this.showChatForm = true;
      this.showChatBox = false;
      localStorage.removeItem("echo_visitor_id");
      localStorage.removeItem("echo_sessionId");
      localStorage.removeItem("echo_privateChannel");
      this.startGlobalChat();
      console.log("start global...");
      this.startGChat();
    },

    async loadChatHistory() {
      try {
        console.log("Loading chat history for session:", this.sessionId);
        const response = await axios.get(
          `${this.serverUrl}chat_history/${this.sessionId}`
        );
        console.log("Chat History Loaded:", response.data);

        if (response.data && response.data.length > 0) {
          this.chats = response.data.map((message) => ({
            sender:
              message.user_id == this.visitor_id ? "You" : `${message.name}`,
            message: message.message,
            created_at: message.created_at,
          }));
        } else {
          console.log("No messages found for this session.");
        }
      } catch (error) {
        console.error("Error fetching chat history:", error);
      }
    },
    scrollToBottom() {
      // Scroll to the bottom of the chat box
      this.$nextTick(() => {
        const chatBox = this.$refs.chatBox;
        if (chatBox) {
          chatBox.scrollTop = chatBox.scrollHeight;
        }
      });
    },
    closeChatForm() {
      this.showChatForm = false;
      this.showChatBox = false;
      this.resetForm();
      this.chats = [];
    },


    toggleChatBox() {
      if (this.showChatBox) {
        this.showChatBox = false;
        this.showChatForm = false;
      }
    },

    openChatForm() {
      const existingVisitorId = localStorage.getItem("echo_visitor_id");
      if (existingVisitorId) {
        const existingSessionId = localStorage.getItem("echo_sessionId");
        this.sessionId = existingSessionId;
        this.visitor_id = existingVisitorId;
        this.showChatBox = true;
        this.showChatForm = false;
        console.log("Session ID already exists:", this.sessionId);
      } else {
        this.showChatForm = true;
      }
    },

    async finishChat() {
      const userConfirmed = confirm(
        "Are you sure you want to close this chat?"
      );
      if (!userConfirmed) {
        return;
      }
      try {
        const response = await axios.post(`${this.serverUrl}closed_chat`, {
          session_id: this.sessionId,
        });

        if (response.data.success) {
          this.isChatClosed = true;
          // alert("The chat session has been closed.");
          console.log(
            "Private Channel to leave: ",
            "prime-" + this.privateChannel
          );
          if (window.Echo) {
            window.Echo.leave("prime-" + this.privateChannel);
          }
          console.log("Attempting to leave channel...");
          this.chats = [];
          this.resetForm();
          this.removeStorage();
          this.startGChat();
          this.showChatBox = false;
          this.showChatForm = false;
        } else {
          alert("There was an error closing the chat.");
          this.chats = [];
          this.resetForm();
          this.removeStorage();
          this.startGChat();
          this.showChatBox = false;
          this.showChatForm = false;
        }
      } catch (error) {
        console.error("Error closing chat:", error);
      }
    },

    resetForm() {
      this.form = {
        email: "",
        firstname: "",
        lastname: "",
        mobile_no: "",
        note: "",
        sessionId: "",
      };
      this.errors = {
        email: null,
        firstname: null,
        lastname: null,
        mobile_no: null,
        note: null,
      };
    },
  },
};
</script>
