<template>
  <div id="live-chat-icon" class="fixed-bottom p-3" style="left: 0">
    <!-- Live Chat Button -->
    <button
      v-if="!showChatForm && !showChatBox"
      @click="showChatForm = true"
      class="btn btn-primary rounded-circle"
      style="
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      Live Chat
    </button>
    <!-- Chat form modal -->
    <div
      v-if="showChatForm"
      class="position-fixed bg-opacity-50 w-100 h-100"
      style="top: 0; left: 0"
    >
      <div
        class="bg-white p-4 rounded shadow"
        style="
          max-width: 400px;
          margin: auto;
          top: 20%;
          left: 50%;
          transform: translateX(-50%);
        "
      >
        <h5>Contact Us</h5>
        <form @submit.prevent="submitChatForm">
          <div class="mb-3">
            <label class="form-label">Email Address</label>
            <input
              v-model="form.email"
              type="email"
              class="form-control"
              required
            />
          </div>
          <div class="mb-3">
            <label class="form-label">First Name</label>
            <input
              v-model="form.firstname"
              type="text"
              class="form-control"
              required
            />
          </div>
          <div class="mb-3">
            <label class="form-label">Last Name</label>
            <input
              v-model="form.lastname"
              type="text"
              class="form-control"
              required
            />
          </div>
          <div class="mb-3">
            <label class="form-label">Telephone Number</label>
            <input
              v-model="form.mobile_no"
              type="text"
              class="form-control"
              required
            />
          </div>
          <div class="mb-3">
            <label class="form-label">Reason for contacting us today ?</label>
            <textarea
              v-model="form.note"
              class="form-control"
              required
            ></textarea>
          </div>
          <button type="submit" class="btn btn-primary">Start Chat</button>
        </form>
        <button @click="showChatForm = false" class="btn btn-danger mt-3">
          Close
        </button>
      </div>
    </div>
    <!-- Chat Box -->
    <div
      v-if="showChatBox"
      class="bg-white p-4 rounded shadow"
      style="max-width: 400px; margin: auto; bottom: 100px"
    >
      <h5>Chat</h5>
      <div class="h-50 overflow-auto border mb-2" style="max-height: 300px">
        <div v-for="(chat, index) in chats" :key="index">
          <strong>{{ chat.sender }}:</strong> {{ chat.message }}
          <button v-if="firstMessageReceived && index === 0" @click="joinChat">
            Join Chat
          </button>
        </div>
      </div>
      <div class="input-group">
        <input
          v-model="newMessage"
          type="text"
          class="form-control"
          placeholder="Type a message..."
          @keydown.enter="sendMessage"
        />
        <button @click="sendMessage" class="btn btn-primary">Send</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
export default {
  mounted() {
    window.Pusher = Pusher;
    this.chatConfig = {
      broadcaster: process.env.VUE_APP_BROADCAST_DRIVER,
      key: process.env.VUE_APP_REVERB_APP_KEY,
      wsHost: process.env.VUE_APP_REVERB_HOST,
      wsPort: process.env.VUE_APP_REVERB_PORT,
      wssPort: process.env.VUE_APP_REVERB_PORT,
      forceTLS: process.env.VUE_APP_REVERB_SCHEME === "https" ? true : false,
      enabledTransports: ["ws", "wss", "sockjs"],
    };

    console.log("Starting global chat...");
    this.startGlobalChat();
    this.startGChat();

    console.log("Listening to global-chat channel...");
  },

  data() {
    return {
      chatConfig: null, // Initially set to null
      showChatForm: false,
      showChatBox: false,
      newMessage: "",
      chats: [],
      visitor_id: "",
      privateChannel: "",
      sound: new Audio("/path/to/notification-sound.mp3"),
      form: {
        email: "",
        firstname: "",
        lastname: "",
        mobile_no: "",
        note: "",
        sessionId: "",
      },
      serverUrl: process.env.VUE_APP_API_URL,
    };
  },
  beforeDestroy() {
    if (window.Echo) {
      window.Echo.disconnect();
    }
  },

  methods: {
    submitChatForm() {
      this.showChatForm = false;
      this.showChatBox = true;
      this.form.sessionId = this.sessionId;
      axios
        .post(this.serverUrl + "chat", this.form)

        .then((response) => {
          this.visitor_id = response.data.contact.id;
          this.showChatForm = false;
          this.showChatBox = true;
          alert("Ticket created successfully. You can now chat!");
          this.resetForm();
        })
        .catch((error) => {
          if (error.response) {
            alert(Object.values(error.response.data.errors).flat().join("\n"));
          } else {
            alert("An unexpected error occurred. Please try again.");
          }
        });
    },

    startGChat() {
      console.log("In GChat.");
      window.Echo = new Echo(this.chatConfig);

      window.Echo.channel("global-chat")
        .subscribed(() => {
          console.log("Successfully subscribed to global-chat channel.");
        })
        .listen(".global.message.sent", (event) => {
          console.log("Received to global-chat channel...");
          console.log("Event received:", event); // Log the entire event object
          try {
            const messageData = event.message;

            const msg = messageData.message;
            console.log("Received message:", msg);

            if (this.visitor_id !== messageData.user_id) {
              if (messageData.chat_type == "private") {
                window.Echo.disconnect();
                if (this.visitor_id == messageData.visitor_id) {
                  // messageData.private_channel
                  this.startPrivate(messageData.private_channel);
                }
              } else {
                this.chats.push({
                  sender: messageData.name,
                  message: msg,
                });
              }
            }
          } catch (error) {
            console.error("Error parsing message data:", error);
          }
        });
    },

    startPrivate(private_channel) {
      this.privateChannel = private_channel;
      window.Echo = new Echo(this.chatConfig);
      console.log("Starting private chat...");
      window.Echo.channel("prime-" + this.privateChannel)
        .subscribed(() => {
          console.log("Successfully subscribed to private-chat. channel.");
        })
        .listen(".prime.message.sent", (event) => {
          console.log("Received to private-chat channel...");
          console.log("Event received:", event); // Log the entire event object
          try {
            const messageData = event.message;
            const msg = messageData.message;
            console.log("Received message:", msg);
            if (this.visitor_id !== messageData.user_id) {
              this.chats.push({
                sender: messageData.name,
                message: msg,
              });
              if (messageData.status === "closed") {
                this.showChatBox = false;
                alert("The chat session has been closed.");
                window.Echo.disconnect();
                console.log("Left private channel:", this.privateChannel);
                this.chats = [];
                this.resetForm();
                this.startGlobalChat();
                console.log("start global...");
                this.startGChat();
                // window.location.reload();
              }
            }
          } catch (error) {
            console.error("Error parsing message data:", error);
          }
        });
    },

    startGlobalChat() {
      axios.post(this.serverUrl + "chat/start-global").then((response) => {
        this.sessionId = response.data.session_id;
      });
    },

    sendMessage() {
      if (this.newMessage.trim() !== "") {
        this.chats.push({
          sender: "You",
          message: this.newMessage,
        });
        axios
          .post(this.serverUrl + "chat/send-message", {
            session_id: this.sessionId,
            message: this.newMessage,
          })
          .then(() => {
            this.newMessage = "";
          });
      }
      this.newMessage = "";
    },
    resetForm() {
      this.form = {
        email: "",
        firstname: "",
        lastname: "",
        mobile_no: "",
        note: "",
        sessionId: "",
      };
    },
  },
};
</script>
